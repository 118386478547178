.bg,
.heder-bg {
  position: absolute;
  top: 107px;
  left: 0;
  background-color: var(--color-ghostwhite);
  width: 1512px;
  height: 1710px;
}

.heder-bg {
  top: 0;
  background-color: var(--neutral-white);
  box-shadow: 0 4px 20px 5px rgba(238, 238, 245, 0.8);
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  width: 1556px;
  height: 170px;
}

.group-303-icon,
.group-305-icon,
.path-599-icon {
  position: absolute;
  height: 72.47%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 27.53%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.group-303-icon,
.group-305-icon {
  height: 22.59%;
  width: 76.08%;
  top: 77.41%;
  right: 11.91%;
  bottom: 0;
  left: 12.01%;
}

.group-305-icon {
  height: 5.19%;
  width: 4.43%;
  top: 71.36%;
  right: 5.37%;
  bottom: 23.46%;
  left: 90.2%;
}

.group-306,
.group-311 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.group-311 {
  height: 86.17%;
  width: 94.9%;
  top: 6.91%;
  right: 2.6%;
  bottom: 6.91%;
  left: 2.5%;
}

.iskcon-logo {
  position: absolute;
  top: 67px;
  left: calc(50% - 684px);
  width: 100px;
  height: 94px;
  overflow: hidden;
}

.notification-child {
  position: absolute;
  top: 3px;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-whitesmoke-100);
  width: 51px;
  height: 51px;
}

.bell-1-icon,
.notification-item {
  position: absolute;
  width: 20px;
  height: 20px;
}

.bell-1-icon {
  top: 18px;
  left: 16px;
  overflow: hidden;
}

.notification-item {
  top: 3px;
  left: 30px;
  border-radius: 50%;
  background-color: var(--color-tomato-100);
}

.div {
  position: absolute;
  top: 0;
  left: 36px;
  line-height: 24px;
  display: inline-block;
  width: 7px;
  height: 17px;
}

.notification {
  position: absolute;
  top: 85px;
  left: 1183px;
  width: 51px;
  height: 54px;
  color: var(--neutral-white);
}

.home {
  white-space: pre-wrap;
}

.live-ranking,
.span1 {
  color: var(--color-brown);
}

.span1 {
  white-space: pre-wrap;
}

.home-record-points-container {
  position: absolute;
  top: 0;
  left: calc(50% - 303px);
  line-height: 24px;
}

.menu-child,
.menu-item {
  position: absolute;
  top: 10px;
  left: 186px;
  width: 8px;
  height: 4px;
  object-fit: contain;
}

.menu-item {
  left: 316px;
}

.menu {
  position: absolute;
  top: 107px;
  left: calc(50% - 320px);
  width: 606px;
  height: 24px;
  font-size: var(--paragraph-2-regular-size);
}

.mask-group-icon,
.profile-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 51px;
  height: 51px;
  object-fit: contain;
}

.profile-child {
  top: 22px;
  left: 187px;
  width: 15px;
  height: 7px;
}

.hare-krishna1 {
  font-weight: 600;
}

.hare-krishna {
  margin: 0;
}

.karan-rochlani {
  margin: 0;
  font-size: var(--font-size-smi-5);
  font-weight: 300;
  color: var(--color-lightslategray-100);
}

.hare-krishna-karan-rochlani-container {
  position: absolute;
  top: 7px;
  left: 69px;
  line-height: 19px;
  text-transform: capitalize;
  display: inline-block;
  width: 136px;
  height: 45px;
}

.heder-bg-parent,
.profile {
  position: absolute;
  top: 86px;
  left: 1257px;
  width: 205px;
  height: 52px;
  font-size: var(--font-size-base);
}

.heder-bg-parent {
  top: -52px;
  left: -22px;
  width: 1556px;
  height: 170px;
  display: none;
  text-align: left;
  font-size: var(--font-size-2xs);
  color: var(--color-steelblue);
  font-family: var(--font-geologica);
}

.list {
  align-self: stretch;
  height: 488px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}

.top-product {
  position: absolute;
  top: 358px;
  left: 72px;
  border-radius: var(--br-xs);
  background-color: var(--neutral-white);
  box-shadow: var(--soft-shadow);
  width: 669px;
  height: 800px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xl);
  box-sizing: border-box;
}

.text,
.text1 {
  position: relative;
  letter-spacing: 0.01em;
}

.text {
  align-self: stretch;
  line-height: 30px;
  font-weight: 600;
}

.text1 {
  font-size: var(--paragraph-2-regular-size);
  line-height: 20px;
  color: var(--neutral-black-black-300);
}

.header,
.title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.title {
  width: 467px;
  flex-direction: column;
  gap: var(--gap-11xs);
}

.header {
  position: absolute;
  top: 382px;
  left: 100.5px;
  width: 612px;
  flex-direction: row;
  text-align: left;
  font-size: var(--subheading-3-medium-size);
  color: var(--neutral-black-black-500);
}

.img {
  width: 40px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--neutral-gray-gray-100);
  height: 40px;
}

.pages,
.text3 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
}

.text3 {
  line-height: 20px;
  font-weight: 500;
}

.pages {
  font-size: var(--text-s-bold-size);
  line-height: 18px;
  color: var(--neutral-gray-gray-500);
}

.img-text,
.text2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xs);
}

.img-text {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}

.text4 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 500;
  white-space: pre-wrap;
}

.list-item {
  position: absolute;
  top: 66px;
  left: 6.4px;
  width: 594px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.text7 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 500;
}

.list-item1,
.list-item2 {
  position: absolute;
  top: 128px;
  left: 6.4px;
  width: 593px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.list-item2 {
  top: 190px;
}

.text14 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 18px;
  font-weight: 600;
}

.information,
.label {
  align-items: center;
  justify-content: center;
}

.label {
  border-radius: var(--br-7xs);
  background-color: var(--secondary-green-green-50);
  display: none;
  flex-direction: column;
  padding: var(--padding-11xs) var(--padding-5xs);
  text-align: center;
  font-size: var(--text-s-bold-size);
  color: var(--secondary-green-green-600);
}

.information {
  display: flex;
  flex-direction: row;
  gap: var(--gap-5xs);
}

.list-item3,
.list-item4,
.list-item5,
.list-item6,
.list-item7,
.list-item8 {
  position: absolute;
  top: 252px;
  left: 6.4px;
  width: 592px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.list-item4,
.list-item5,
.list-item6,
.list-item7,
.list-item8 {
  top: 314px;
  width: 591px;
}

.list-item5,
.list-item6,
.list-item7,
.list-item8 {
  top: 376px;
  width: 590px;
}

.list-item6,
.list-item7,
.list-item8 {
  top: 438px;
  width: 589px;
}

.list-item7,
.list-item8 {
  top: 500px;
  width: 596px;
}

.list-item8 {
  top: 562px;
  width: 591px;
}

.group-child {
  position: absolute;
  top: 0;
  left: 8px;
  border-radius: var(--br-7xs);
  background-color: var(--color-ghostwhite);
  border: 0.6px solid var(--color-lightsteelblue);
  box-sizing: border-box;
  width: 636.8px;
  height: 46px;
}

.order-status,
.order-status1,
.order-status2 {
  position: absolute;
  top: 10px;
  left: 25.2px;
  font-size: var(--font-size-base);
  font-family: var(--font-geologica);
  color: var(--color-steelblue);
  display: inline-block;
  width: 80.2px;
  height: 21.4px;
}

.order-status1,
.order-status2 {
  left: 551.7px;
}

.order-status2 {
  left: 333.5px;
}

.group-item {
  position: absolute;
  top: 626.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 634px;
  height: 1px;
}

.list-item-parent {
  position: absolute;
  top: 447px;
  left: 89.6px;
  width: 644.8px;
  height: 627px;
  text-align: left;
  font-size: var(--paragraph-2-regular-size);
  color: var(--neutral-black-black-500);
}

.title1 {
  width: 737px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-11xs);
}

.header1,
.title1,
.top-product1 {
  display: flex;
  justify-content: flex-start;
}

.header1 {
  width: 636px;
  flex-direction: row;
  align-items: flex-start;
  z-index: 0;
}

.top-product1 {
  position: absolute;
  top: 0;
  left: 661px;
  border-radius: var(--br-xs);
  background-color: var(--neutral-white);
  box-shadow: var(--soft-shadow);
  width: 678px;
  height: 800px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-5xl);
  box-sizing: border-box;
  font-size: var(--subheading-3-medium-size);
  color: var(--neutral-black-black-500);
  font-family: var(--text-s-bold);
}

.group-inner {
  position: absolute;
  top: 155px;
  height: 45px;
}

.group-child1,
.group-inner,
.rectangle-div {
  left: 1204.3px;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 114.1px;
}

.rectangle-div {
  position: absolute;
  top: 217px;
  height: 45px;
}

.group-child1 {
  top: 278px;
}

.group-child1,
.group-child2,
.group-child3 {
  position: absolute;
  height: 45px;
}

.group-child2 {
  top: 340px;
  left: 1204.3px;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 114.1px;
}

.group-child3 {
  top: 402px;
}

.group-child3,
.group-child4,
.group-child5 {
  left: 1204.3px;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 114.1px;
}

.group-child4 {
  position: absolute;
  top: 464px;
  height: 45px;
}

.group-child5 {
  top: 525px;
}

.group-child5,
.group-child6,
.group-child7 {
  position: absolute;
  height: 45px;
}

.group-child6 {
  top: 587px;
  left: 1204.3px;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 114.1px;
}

.group-child7 {
  top: 651px;
}

.group-child7,
.group-child8 {
  left: 1204.3px;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 114.1px;
}

.group-child10,
.group-child8,
.group-child9 {
  position: absolute;
  top: 734px;
  height: 45px;
}

.group-child9 {
  left: 1032px;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 139.9px;
}

.group-child10 {
  left: 834.6px;
  border-radius: 11px;
  background-color: var(--primary);
  width: 164.8px;
}

.list-item10,
.list-item11,
.list-item12,
.list-item13,
.list-item14,
.list-item15,
.list-item16,
.list-item9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 611.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.list-item10,
.list-item11,
.list-item12,
.list-item13,
.list-item14,
.list-item15,
.list-item16 {
  top: 62px;
}

.list-item11,
.list-item12,
.list-item13,
.list-item14,
.list-item15,
.list-item16 {
  top: 124px;
}

.list-item12,
.list-item13,
.list-item14,
.list-item15,
.list-item16 {
  top: 186px;
}

.list-item13,
.list-item14,
.list-item15,
.list-item16 {
  top: 248px;
}

.list-item14,
.list-item15,
.list-item16 {
  top: 310px;
}

.list-item15,
.list-item16 {
  top: 372px;
}

.list-item16 {
  top: 434px;
}

.text59 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
}

.list-item17 {
  position: absolute;
  top: 496px;
  left: 0;
  width: 611.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.group-child11,
.list1 {
  position: absolute;
  left: 681.6px;
}

.list1 {
  top: 158px;
  width: 611.1px;
  height: 536px;
  font-size: var(--paragraph-2-regular-size);
  color: var(--neutral-black-black-500);
  font-family: var(--text-s-bold);
}

.group-child11 {
  top: 93px;
  border-radius: var(--br-7xs);
  background-color: var(--color-ghostwhite);
  border: 0.6px solid var(--color-lightsteelblue);
  box-sizing: border-box;
  width: 636.8px;
  height: 46px;
}

.order-status3,
.order-status4,
.order-status5 {
  position: absolute;
  top: 103px;
  left: 698.8px;
  font-size: var(--font-size-base);
  color: var(--color-steelblue);
  display: inline-block;
  width: 80.2px;
  height: 21.4px;
}

.order-status4,
.order-status5 {
  left: 1228.3px;
}

.order-status5 {
  left: 1090.1px;
}

.group-child12,
.group-child13 {
  position: absolute;
  top: 0;
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 45px;
}

.group-child12 {
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  width: 169.8px;
}

.group-child13 {
  left: 42.7px;
  background-color: var(--neutral-white);
  width: 84.4px;
}

.div1,
.div2,
.div3 {
  position: absolute;
  display: inline-block;
}

.div1 {
  top: 12px;
  left: 58.3px;
  font-size: var(--font-size-base);
  color: var(--color-gray-200);
  width: 53.1px;
}

.div2,
.div3 {
  top: 10px;
  left: 140.6px;
  width: 12.5px;
}

.div3 {
  left: 15.6px;
  width: 7.3px;
}

.rectangle-parent {
  position: absolute;
  top: 155px;
  left: 1012.2px;
  width: 169.8px;
  height: 45px;
}

.group-child14,
.group-child15 {
  position: absolute;
  top: 0;
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 45px;
}

.group-child14 {
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  width: 169.9px;
}

.group-child15 {
  left: 42.7px;
  background-color: var(--neutral-white);
  width: 84.5px;
}

.div4,
.div5 {
  position: absolute;
  display: inline-block;
}

.div4 {
  top: 12px;
  left: 58.4px;
  font-size: var(--font-size-base);
  color: var(--color-gray-200);
  width: 53.2px;
}

.div5 {
  top: 10px;
  left: 140.8px;
  width: 12.5px;
}

.rectangle-group {
  position: absolute;
  top: 216px;
  left: 1012px;
  width: 169.9px;
  height: 45px;
}

.group-div,
.rectangle-container,
.rectangle-parent1,
.rectangle-parent2 {
  position: absolute;
  top: 278px;
  left: 1012px;
  width: 169.9px;
  height: 45px;
}

.group-div,
.rectangle-parent1,
.rectangle-parent2 {
  top: 339px;
}

.rectangle-parent1,
.rectangle-parent2 {
  top: 463px;
}

.rectangle-parent2 {
  top: 402px;
}

.line-div,
.rectangle-parent3,
.rectangle-parent4,
.rectangle-parent5 {
  position: absolute;
  top: 525px;
  left: 1012px;
  width: 169.9px;
  height: 45px;
}

.line-div,
.rectangle-parent4,
.rectangle-parent5 {
  top: 586px;
}

.line-div,
.rectangle-parent5 {
  top: 649px;
}

.line-div {
  top: 715.5px;
  left: 681.1px;
  border-top: 1px solid var(--color-lightgray);
  box-sizing: border-box;
  width: 636.1px;
  height: 1px;
}

.save,
.total-count,
.total-points {
  position: absolute;
  top: 746px;
  left: 0;
  display: inline-block;
  width: 113px;
}

.save,
.total-points {
  left: 689px;
}

.save {
  top: 743px;
  left: 890px;
  color: var(--neutral-white);
  width: 53px;
}

.div28,
.div29,
.total-points1 {
  position: absolute;
  top: 746px;
  left: -3px;
  display: none;
  width: 113px;
}

.div28,
.div29 {
  top: 743px;
  left: 1069px;
  display: inline-block;
  width: 65px;
}

.div29 {
  left: 1243px;
  width: 48px;
}

.group-child30 {
  position: absolute;
  top: 734px;
  left: 510.3px;
  border-radius: var(--br-7xs);
  background-color: var(--color-gray-100);
  border: 0.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 114.1px;
  height: 45px;
}

.div30 {
  position: absolute;
  top: 743px;
  left: 543px;
  display: inline-block;
  width: 54px;
}

.top-product-parent {
  position: absolute;
  top: 358px;
  left: 96px;
  width: 1339px;
  height: 800px;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-geologica);
}

.notification1 {
  position: absolute;
  top: 85px;
  left: 1183px;
  width: 51px;
  height: 54px;
  font-size: var(--font-size-2xs);
}

.record-points {
  color: var(--color-steelblue);
  white-space: pre-wrap;
}

.home-record-points-container1 {
  position: absolute;
  top: 0;
  left: calc(50% - 320px);
  line-height: 24px;
}

.polygon-icon {
  position: absolute;
  top: 10px;
  left: 324px;
  width: 8px;
  height: 4px;
  object-fit: contain;
}

.menu1 {
  position: absolute;
  top: 103px;
  left: calc(50% - 510px);
  width: 640px;
  height: 24px;
  color: var(--color-brown);
}

.profile1 {
  position: absolute;
  top: 86px;
  left: 1257px;
  width: 205px;
  height: 52px;
  font-size: var(--font-size-base);
  color: var(--color-steelblue);
}

.update-score {
  position: relative;
  width: Fixed (161px) px;
  height: Fixed (36px) px;
  top: 43px;
  left: 956px;
  padding: 17px 16px 17px 16px;
  gap: 10px;
  border-radius: 12px 0px 0px 0px;
  opacity: 0px;
}

.update-score-button {
  position: relative;
}

.label-container {
  width: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttons,
.heder {
  position: absolute;
}

.buttons {
  top: 95px;
  left: 978px;
  border-radius: var(--br-xs);
  background-color: var(--primary);
  width: 161px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 17px 16px;
  box-sizing: border-box;
  text-align: center;
  font-family: var(--label-1-regular);
}

.heder {
  top: -52px;
  left: -22px;
  width: 1556px;
  height: 170px;
  text-align: left;
  font-size: var(--paragraph-2-regular-size);
  color: var(--neutral-white);
  font-family: var(--font-geologica);
}

.group-table-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 14px;
  background-color: var(--neutral-white);
  width: 678px;
  height: 147px;
}

.group-table-item {
  position: absolute;
  top: 24px;
  left: 21px;
  border-radius: 5px;
  background-color: #fff5e8;
  width: 32.6px;
  height: 31px;
}

.bag-icon {
  position: absolute;
  height: 10.88%;
  width: 2.33%;
  top: 21.09%;
  right: 93.39%;
  bottom: 68.03%;
  left: 4.28%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.div32,
.div33 {
  position: relative;
}

.div32 {
  font-weight: 500;
}

.div33 {
  font-size: var(--text-s-bold-size);
  font-family: var(--label-1-regular);
  color: var(--action-green);
  opacity: 0;
}

.parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xs);
  font-size: var(--subheading-3-medium-size);
  color: var(--black-60);
  font-family: var(--subheading-3-medium);
}

.all-india-ranking-parent,
.books-distributed-parent,
.total-score-parent,
.zonal-ranking-parent {
  position: absolute;
  top: 81px;
  left: 21px;
  width: 115.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.books-distributed-parent,
.total-score-parent,
.zonal-ranking-parent {
  left: 208.1px;
}

.books-distributed-parent,
.total-score-parent {
  left: 370px;
}

.books-distributed-parent {
  left: 517.2px;
}

.your-stats {
  position: absolute;
  top: 29px;
  left: 64.1px;
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-geologica);
  color: var(--color-steelblue);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86.2px;
}

.group-table {
  position: absolute;
  top: 163px;
  left: 757px;
  width: 678px;
  height: 147px;
  text-align: left;
  font-size: var(--paragraph-2-regular-size);
  color: var(--black-30);
  font-family: var(--label-1-regular);
}

.group-child31 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--neutral-white);
  box-shadow: 0 4px 16px #f0f0fc;
  width: 670px;
  height: 151px;
}

.recording-of-scores1,
.track-real-time-rankings-container {
  position: absolute;
  text-transform: capitalize;
  display: inline-block;
}

.recording-of-scores1 {
  top: 15.1px;
  left: 32px;
  line-height: 43px;
  font-weight: 600;
  width: 581px;
  height: 46.4px;
}

.track-real-time-rankings-container {
  top: 66.9px;
  left: 31px;
  font-size: 17px;
  line-height: 28px;
  color: #777;
  width: 589px;
  height: 60.4px;
}

.rectangle-parent6 {
  position: absolute;
  top: 163px;
  left: 72px;
  width: 670px;
  height: 151px;
  text-align: left;
  font-size: 26px;
  color: var(--color-steelblue);
  font-family: var(--font-montserrat);
}

.progress-child {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--secondary-orange-orange-500);
  width: 54px;
}

.progress1 {
  flex: 1;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--neutral-gray-gray-100);
  height: 8px;
  overflow: hidden;
}

.text60 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 18px;
}

.cell,
.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.progress {
  width: 100%;
  height: 10px;
  background-color: #f2f2f2;
  gap: var(--gap-5xs);
}

.cell {
  position: absolute;
  top: 508px;
  left: 389px;
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-gray-gray-50);
  box-sizing: border-box;
  width: 152px;
  height: 53px;
  padding: var(--padding-lg) var(--padding-3xl);
}

.progress-item {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--secondary-orange-orange-500);
  width: 45px;
}

.cell1 {
  position: absolute;
  top: 568px;
  left: 389px;
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-gray-gray-50);
  box-sizing: border-box;
  width: 152px;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-3xl);
}

.progress-inner {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--secondary-orange-orange-500);
  width: 36px;
}

.cell2 {
  position: absolute;
  top: 630px;
  left: 389px;
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-gray-gray-50);
  box-sizing: border-box;
  width: 152px;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-3xl);
}

.progress-child1 {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--secondary-green-green-500);
  width: 65px;
}

.cell3 {
  position: absolute;
  top: 693px;
  left: 389px;
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-gray-gray-50);
  box-sizing: border-box;
  width: 152px;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-3xl);
  color: var(--secondary-green-green-600);
}

.progress-child2 {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--secondary-red-red-500);
  width: 17px;
}

.progress9 {
  flex: 1;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--neutral-gray-gray-100);
  height: 8px;
}

.cell4 {
  position: absolute;
  top: 754px;
  left: 389px;
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-gray-gray-50);
  box-sizing: border-box;
  width: 152px;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-3xl);
  color: var(--secondary-red-red-500);
}

.progress-child3 {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--secondary-orange-orange-500);
  width: 17px;
}

.cell5,
.cell6,
.cell7 {
  position: absolute;
  top: 817px;
  left: 389px;
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-gray-gray-50);
  box-sizing: border-box;
  width: 152px;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-3xl);
}

.cell6,
.cell7 {
  top: 875px;
}

.cell7 {
  top: 936px;
  color: var(--secondary-green-green-600);
}

.progress16 {
  width: 100px;
  height: 44px;
}

.cell8 {
  position: absolute;
  top: 997px;
  left: 389px;
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-gray-gray-50);
  box-sizing: border-box;
  width: 152px;
  height: 54px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-3xl);
}

.progress17 {
  position: absolute;
  top: 1020px;
  left: 411px;
  border-radius: var(--br-81xl);
  background-color: var(--neutral-gray-gray-100);
  width: 65px;
  height: 8px;
}

.text68 {
  position: absolute;
  top: 1015px;
  left: 484px;
  letter-spacing: 0.01em;
  line-height: 18px;
  color: var(--secondary-red-red-500);
}

.progress18 {
  position: absolute;
  top: 1109.5px;
  left: 411px;
  border-radius: var(--br-81xl);
  background-color: var(--neutral-gray-gray-100);
  width: 65px;
  height: 8px;
}

.text69 {
  position: absolute;
  top: 1104.5px;
  left: 484px;
  letter-spacing: 0.01em;
  line-height: 18px;
}

.recording-of-scores {
  width: 100%;
  position: fixed;
  background-color: var(--neutral-white);
  height: 100px;
  /* overflow: hidden; */
  text-align: center;
  font-size: var(--text-s-bold-size);
  color: var(--secondary-orange-orange-500);
  font-family: var(--text-s-bold);
  /* background-color: #efeff1; */
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-box {
  width: 163px;
  height: 45px;
  top: 473px;
  left: 1089px;
  gap: 0px;
  border-radius: 6px 0px 0px 0px;
  border: 0.6px 0px 0px 0px;
}

.count-input {
  width: 84px;
  height: 40px;
  margin-top: 5px;
  /* top: 473px;
    left: 1130px;
    gap: 0px; */
  border: 0.6px 0px 0px 0px;
  border: 0.6px solid #dfdfdf;
  background: #ffffff;
  padding: inherit 5px;

  /* width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 10px; */
}

.counter-button {
  width: 30px;
  height: 40px;
  border: none;
  /* background-color: #a1a5a8; */
  color: #000;
  font-size: 18px;
  cursor: pointer;
  /* border-radius: 5px; */
}

.score-points p {
  display: flex;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 10px 20px;
}

.score-points p span {
  padding: 0 5px;
}

.common-container {
  /* margin: 70px 70px; */
  background: #f9f9fd;
}

.update-score-container .card {
  /* padding: 20px 15px !important; */
  max-width: 100%;
  height: auto;
}

.update-score-container .card-body {
  text-align: left;
}

.stats-header {
  color: #43567d;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.card-text.stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 11px;
}

.update-score-container .content-table {
  margin: 40px 0;
  text-align: left;
  background: #fff;
  /* padding: 24px; */
}

.card-title {
  font-size: 26px;
  font-weight: 600;
  color: #43567d;
}

.card-text {
  font-size: 17px;
  font-weight: 400;
  color: #777777;
}

.content-title {
  font-size: 20px;
  font-weight: 600;
  color: #1d1f2c;
}

.font-weight-bold {
  font-size: 14px;
  font-weight: 500;
  color: #1d1f2c;
}

.pages-count {
  font-size: 12px;
  font-weight: 400;
  color: #667085;
}

.points-count {
  font-size: 14px;
  font-weight: 500;
  color: #1d1f2c;
  text-align: center;
}

.update-score-container .content-table .content-title {
  font-size: 20px;
  text-transform: capitalize;
}

.update-score-container .content-table .content-subtitle {
  font-size: 14px;
  color: #777980;
  font-weight: 400;
}

.update-score-container .content-table thead tr th {
  /* background-color: #f9f9fd;
  color: #43567d; */
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #43567d;
  background: #f9f9fd;
  /* border-top: 1px solid #b4bbc9;
  border-bottom: 1px solid #b4bbc9; */
}
.filter-container-comparing {
  height: 77px;
  top: 357px;
  left: 75px;
  /* border: 0.6px solid #d5d5d5; */
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* padding: 10px 10px 10px 42px; */
}

.filter-container {
  height: 58px;
  top: 357px;
  left: 75px;
  border: 0.6px solid #d5d5d5;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.filter-container .filter-border {
  border-left: 0.3px solid #d5d5d5;
  height: 58px;
}

.filter-container .filter-by {
  width: 120px;
  align-items: center;
  justify-content: center;
}

.filter-text {
  color: #43567d;
  font-size: 14px;
  font-weight: 500;
}

.filter-textfordrop-down {
  font-family: Geologica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  width: 72.36px;
  height: 58px;
  /* border: 0.3px solid #979797; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  opacity: 1; /* Ensure visibility */
}

.filter-container p {
  margin: 0 !important;
}

.filter-container .form-select {
  border: none !important;
  color: #43567d;
  font-size: 14px;
  font-weight: 500;
  width: 22%;
}

.filter-container .form-select:focus {
  border: none !important;
  box-shadow: none !important;
}

.search-container {
  width: 330px;
  height: 77px;
  border: 0.6px solid #d5d5d5;
  border-radius: 0px 10px 10px 0px;
}

.btn-primarysave-liveranking {
  padding: 15px 15px;
  height: 57px;
  margin: 10px;
  margin-top: 20px;
  background-color: transparent;
  color: #43567d;
  border: 1px solid #43567d;
  border-radius: 10px;
  cursor: pointer;
  width: 90%;
  /* margin-left: 38px; */
  font-weight: 400;
  font-size: 16px;
}
.btn-primarysave-liveranking:hover {
  background-color: #9f1d21;
  border: none;
}
.btn-primarysave-liveranking:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-primarysave {
  padding: 10px 16px;
  background-color: #f8f9ff;
  color: #6e717f;
  border: 1px solid #dbdff2;
  border-radius: 6px;
  cursor: pointer;
  /* width: 40%; */
  /* margin-left: 38px; */
  font-weight: 400;
  font-size: 16px;
}

.btn-primarysave:hover {
  background: #9f1d21;
  border: none;
  color: #fff;
}

.btn-primarysave:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-primarysave-active {
  background: #9f1d21;
  color: #fff;
}

td {
  text-align: center;
}

#dropdown-basic-button {
  height: 45px !important;
}

.live-ranking .filters-wrap {
  display: flex;
}

.live-ranking .input-group-prepend {
  margin-left: 10px;
}

.live-ranking .input-group-text {
  height: 77px;
  border-radius: 10px 0px 0px 10px;
}

.live-ranking .live-search-input {
  display: flex;
  padding: 0;
}

.live-ranking .custom-table {
  border: 1px solid #d5d5d5;
  border-radius: 10px !important;
}

.live-ranking .table-row-header {
  background-color: #f4f8ff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  color: #707c97;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .col-6 {
    width: 100%;
    margin-bottom: 16px;
  }

  .table {
    display: block;
    overflow-x: auto;
  }
}

.rdr-buttons-position {
  bottom: 1rem;
}

.date-range-input {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s;
}

.date-range-input.full-width {
  width: 80%;
  background-color: #f8f9ff !important;
  color: #43567d !important;
  border: 1px solid #dbdff2;
}

.date-range-input:focus {
  border-color: #007bff;
  outline: none;
}

.date-range-selector-container {
  position: absolute;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pledge-responsive {
  /* margin-top: 110px !important; */
}

.pledge-responsive .update-score-container .content-table {
  margin: 0;
  padding: 24px;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}

.pledge-responsive .update-score-container .row {
  margin: 0;
  padding: 0;
}

.pledge-responsive .row .card-padding > * {
  padding: 0px !important;
}

.pledge-responsive .pledge-pints {
  height: auto;
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}

.update-responsive {
  /* margin-top: 110px; */
}

.update-responsive .ranking-count {
  color: #45464e;
  font-size: 20px;
  font-weight: 600;
}

.update-responsive .ranking-status-text {
  color: #8b8d97;
  font-size: 14px;
  font-weight: 400;
  padding-right: 5px;
}

.update-responsive .update-score-container .row {
  margin: 0;
  padding: 0;
}

.update-responsive .update-score-container .event-filters {
  display: flex;
  justify-content: space-between;
}

.card {
  padding: 0 !important;
  border: none;
}

.pledge-count-total-div {
  /* display: flex;
  justify-content: space-between;
  padding: 13px 72px 13px 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dee2e6; */
  border-top: 1px solid #dee2e6;
}

@media (max-width: 768px) {
  .pledge-responsive .update-score-container .row {
    margin: 0;
    padding: 0;
  }

  .pledge-responsive .update-score-container .content-table {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
  }

  .pledge-responsive .input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100px;
    margin-top: 10px;
  }

  .pledge-responsive td {
    border: none;
  }

  .pledge-responsive th {
    border-bottom: none;
  }

  .pledge-responsive .counter-button-left {
    width: 25px;
    height: 50px;
    border-radius: 5px 0px 0px 5px;
    margin-top: 5px;
  }

  .pledge-responsive .counter-button-right {
    width: 25px;
    height: 50px;
    border-radius: 0px 5px 5px 0px;
    margin-top: 5px;
  }

  .pledge-responsive .count-input {
    width: 40px;
    height: 50px;
  }

  .pledge-responsive .pledge-image {
    display: none;
  }

  .pledge-responsive .hide-points {
    display: none;
  }

  .pledge-responsive .btn-primarysave {
    width: 100%;
    margin: 0;
  }

  .pledge-responsive .pledge-pints {
    height: auto;
    padding: 0;
    border-radius: none;
    border: none;
    /* background-color: #a1a5a8; */
  }

  .update-responsive .update-score-container .row {
    padding: 0px;
    margin: 0;
  }

  .update-responsive .card-text.stats {
    /* flex-wrap: nowrap; */
  }

  .update-responsive .update-score-container .card {
    height: auto;
  }

  .update-responsive .card-body {
    padding: 10px !important;
  }

  .update-responsive .update-score-container .event-filters {
    display: block;
    /* justify-content: space-between; */
  }

  .update-responsive .update-score-container .date-drop-down {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .live-ranking .filters-wrap {
    padding: 0px 10px 10px 0px;
    display: block;
  }

  .live-search-input {
    margin-top: 10px;
  }

  .live-ranking .input-group-prepend {
    margin-left: 0;
  }

  .filters-wrap .col-9 {
    width: 100% !important;
  }

  .filters-wrap .col-3 {
    width: 100% !important;
  }

  .pledge-count-total-div {
    padding-right: 42px;
  }

  .tr-mobile-width {
    width: 100%;
  }

  .card-shadow {
    margin-top: 10px;
  }

  .update-responsive .ranking-status-text {
    font-size: 12px;
  }

  .update-responsive .ranking-count {
    font-size: 16px;
  }

  .total-table-points {
    margin-top: 25px;
  }

  .custom-button {
    margin: 4px !important;
    font-size: 14px;
  }
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.Modal {
  position: absolute;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background: #fff;
  overflow: auto;
  webkitoverflowscrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
.row-heading {
  display: flex;
  flex-direction: column;
}
.table-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  border: 1px solid #ccc;
}

.column {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}

.header {
  font-weight: bold;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.cell {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.cell:last-child {
  border-bottom: none;
}

.total-count-text-td {
  padding: 17px 10px !important;
  text-align: end;
}

.content-table .table-padding-bottom {
  padding-bottom: 10px;
}

.pledge-padding-bottom {
  padding-bottom: 10px;
}

.custom-button {
  width: auto !important;
  margin: 0;
}

.total-count-padding {
  padding: 17px 12px !important;
  text-align: end;
}

.modal-gap {
  gap: 20px;
}

.count-value-text {
  color: #202224 !important;
  font-size: 16px;
  font-weight: 400;
}
.custom-comparetable {
  /* border-radius: 15px 15px 0px 0px; */
  /* overflow: hidden; */
}

.custom-compare-btn {
  width: 195px;
  height: 45px;
  border-radius: 6px;
  background-color: #9f1d21;
  border: 0.6px solid #ffffff;
  color: #fff;
}

.custom-compare-btn:hover {
  opacity: 0.8; /* Slightly reduce opacity on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.custom-compare-btn:active {
  transform: translateY(0); /* Reset position on click */
}

.card-shadow {
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
  border-radius: 12px;
}

.total-table-points {
  font-size: 18px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  border: 0.6px solid #dfdfdf;
  background: #fafafa;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}

.content-table .table > thead {
  border: transparent !important;
}

.update-score-container .content-table thead tr th:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  /* border-left: 1px solid #b4bbc9; */
}

.update-score-container .content-table thead tr th:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.reset-live-filters {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #9f1d21;
}

.live-ranking-table tbody tr td {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.1px;
  text-align: left;
  color: #202224;
}

.live-ranking-table tbody tr:last-child td {
  border-bottom: none;
}
@media (min-width: 768px) {
  .live-ranking-table .custom-table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
  }
}

.ReactModalPortal .table-responsive {
  border: 1px solid #d5d5d5 !important;
  border-radius: 10px !important;
}

.ReactModalPortal .custom-comparetable tbody {
  border-top: transparent !important ;
}

.ReactModalPortal .custom-comparetable tbody tr:first-child td {
  background-color: #eeeff6;
  color: #707c97 !important;
}

.ReactModalPortal .custom-comparetable tbody tr:first-child td .filter-text {
  background-color: #eeeff6;
  color: #707c97 !important;
}

.ReactModalPortal .custom-comparetable tbody tr td .filter-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  text-align: left;
  color: #202224;
}

.ReactModalPortal .custom-comparetable {
  margin: 0 !important;
  height: 100%;
  overflow: auto;
}

.live-ranking-table .table-row-header {
  line-height: 33px !important;
}

.live-ranking-table td {
  line-height: 30px !important;
}

.ReactModalPortal .compare-modal {
  overflow: hidden !important;
  background-color: transparent !important;
}

.compare-table-body {
  height: 100%;
  /* overflow-y: auto;
  overflow-x: hidden; */
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
}
