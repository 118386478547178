.audio-wrapper {
  margin-top: 110px;
}

.audio-wrapper .row {
  padding: 0px !important;
}

.audio-wrapper .audio-table {
  margin-top: 40px;
}

.audio-wrapper .blog-top-header {
  display: flex;
  height: 57px;
  flex-direction: row;
}

.audio-wrapper .blog-filters-row {
  display: flex;
  height: 57px;
  flex-direction: row;
  gap: 20px;
}

.audio-wrapper .blog-heading {
  font-weight: 700;
  text-align: left;
  color: #43567d;
}

.audio-wrapper .form-select {
  height: 57px;
}

.audio-wrapper .input-group-text {
  height: 57px;
  border-radius: 10px 0px 0px 10px;
  background-color: #ffffff;
}

.audio-wrapper .search-container {
  height: 57px;
}

.audio-wrapper .audio-img {
  width: 57px;
  height: 57px;
  border-radius: 10px;
}

.audio-wrapper .audio-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.audio-wrapper .row {
  display: flex;
  flex-direction: row;
}

.audio-wrapper .column {
  padding: 10px;
}

.audio-wrapper .text-start {
  text-align: left;
}

.audio-wrapper .table-cell-width {
  width: 33%;
}

.audio-wrapper .audio-list {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #dde0e7;
}

.audio-wrapper .download-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-wrapper .download-option > div {
  text-align: left;
}

@media screen and (max-width: 1020px) {
  .audio-wrapper .video-card {
    margin-top: 10px !important;
    width: 100%;
    height: 100%;
  }

  .audio-wrapper .video-card .image {
    margin-top: 10px !important;
    width: 100%;
  }

  .audio-wrapper .blog-top-header {
    padding: 0px !important;
    flex-direction: row;
  }

  .audio-wrapper .row .col-sm {
    margin-bottom: 10px;
  }

  .audio-wrapper .blog-top-header {
    display: block;
    height: 57px;
    flex-direction: row;
  }

  .audio-wrapper .blogs-container {
    margin-top: 40px;
  }
  .audio-wrapper .column {
    padding: 6px;
}
}
