.forum-wrapper {
  /* margin-top: 110px; */
}

.forum-wrapper .row {
  padding: 0px !important;
}

.ask-question {
  border-radius: 10px;
  border: 1px solid #d4def1;
  background: #9f1d21;
  text-align: center;
  padding: 16px;
  width: 100%;
}

.question-text {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.ask-question:hover {
  background-color: #ffffff;
  color: #9f1d21;
}

.back-button {
  border-radius: 10px;
  border: 1px solid #9f1d21;
  text-align: center;
  padding: 16px;
}
.back-forum {
  font-size: 17px;
  font-weight: 600;
  color: #9f1d21;
}

.category-text {
  font-size: 18px;
  font-weight: 600;
  color: #43567d;
}

.category-list-label {
  border-radius: 10px;
  border: 2px solid #e9e9f5;
  padding: 16px;
  width: 100%;
  cursor: pointer;
}

/* .custom-radio{
  accent-color: #1CD15A;
} */

.category-list {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #848fa8;
}

.forum-filters-row {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 10px;
}

.questions-count {
  color: #9f1d21;
  font-weight: 400;
  font-size: 16px;
  margin-right: 120px;
}

.forum-wrapper .forum-input-group-text {
  height: 57px;
  border-radius: 10px 0px 0px 10px;
  background-color: #ffffff;
  padding: 25px;
}

.forum-wrapper .forum-input-group-text-reset {
  height: 57px;
  border-radius: 0px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 0px 10px 10px 0px;
}

.forum-wrapper .bottom-nav {
  margin-bottom: 70px;
}

.no-questions-found {
  height: 71vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forum-wrapper .form-select .filter-text {
  color: #43567d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.forum-wrapper .form-select {
  color: #43567d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.forum-wrapper .answer-actions {
  color: #43567d !important;
  cursor: pointer;
}

.forum-wrapper .answer-actions-text {
  color: #43567d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.forum-wrapper .forum-answer-section {
  border-top: 1px solid #d4def1;
}

.forum-wrapper .user-comments {
  border-top: 1px solid #d4def1;
}

.forum-wrapper .answers-stats {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #9fa8ba;
  line-height: 28px;
}

.forum-wrapper .answer-comments-item-list {
  /* border-top: 1px solid #d4def1; */
  margin-left: 70px;
}

.forum-wrapper .comment-box {
  margin-left: 70px;
}

.forum-wrapper .forum-search-container {
  height: 57px;
  max-width: 310px;
  box-shadow: none;
}

.forum-wrapper .user-name {
  color: #43567d;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

.forum-wrapper .blog-details-date {
  cursor: pointer;
}

.forum-ans-section .read-more {
  position: relative;
}

.forum-ans-section .read-more-link {
  position: absolute;
  left: 42%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to bottom, rgb(184 75 75 / 0%) 0%, rgb(255, 255, 255) 100%);
  padding: 10px;
  border-radius: 50px;
  top: 127%;
  text-align: center;
  width: 125%;
}

.forum-ans-section .continue-reading {
  padding: 3px 10px;
  border-radius: 13px;
  color: #a88181;
  margin-top: 37px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #d4def1;
}

.forum-ans-section .continue-reading:hover {
  color: #9f1d21;
}

.forum-wrapper .form-select {
  height: 57px;
  border-radius: 00px 10px 10px 0px;
}

.ask-question-select {
  border-radius: 10px !important;
}

.forum-left {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.forum-right {
  border-radius: 10px;
  /* background-color: #ffffff; */
}

.forum-item-list {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.forum-item-text {
  font-size: 16px;
  font-weight: 600;
  color: #939fb7;
}

.right-section-wrapper {
  padding: 15px 25px;
  background-color: #ffffff;
  margin-bottom: 20px;
  border-radius: 10px;
  display: block;
  border: 1px solid #e9e9f3;
}

.right-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.right-section-header .delete {
  cursor: pointer;
  margin-left: 20px;
}

.user-question {
  color: #9f1d21;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px !important;
  line-height: 28px;
}

.forum-image {
  height: 120px;
  width: 120px;
  border-radius: 8px;
}

.user-answer {
  color: #43567d;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  line-height: 28px;
  text-align: justify;
}

.answer-section {
  display: flex;
  padding: 10px;
  background-color: #f4f5f7;
  border-radius: 10px;
}

.right-section-wrapper p {
  padding-bottom: 0px !important;
}

.forum-ans-section {
  display: flex;
  flex-direction: column;
  padding: 12px 18px;
}

.share-reply-section {
  display: flex;
  gap: 30px;
  padding: 10px 10px;
}

/* details */

.forum-details .forum-details-question {
  color: #9f1d21;
  font-size: 24px;
  font-weight: 500;
  padding: 8px;
  line-height: 30px;
}

.forum-details .accept-answer {
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: #43567d;
}

.forum-details .answer-comments-section .user-answer {
  padding-left: 60px;
}

.forum-details .answer-comments-section .share-reply-section {
  padding-left: 70px;
}

@media screen and (max-width: 1020px) {
  .forum-right {
    margin-top: 10px;
  }
  .share-reply-section {
    justify-content: space-between;
    gap: 10px;
  }
  .forum-wrapper .user-header-div {
    height: auto;
  }

  .forum-filters-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px;
  }

  .forum-search-wrapper {
    display: flex;
    justify-content: flex-start !important;
  }

  .forum-search-div {
    width: 100% !important;
  }

  .questions-count {
    margin-right: 0px;
  }
  .forum-details .report-topic {
    display: none;
  }
  .forum-details .answer-comments-section .user-answer {
    padding: 0;
  }

  .forum-details .answer-comments-section .share-reply-section {
    padding-left: 10px;
  }

  .forum-ans-section .read-more-link {
    top: 103%;
  }

  .forum-image {
    /* display: none; */
    height: 75px;
    width: 75px;
  }
}

.forum-search-wrapper {
  display: flex;
  justify-content: end;
}

.forum-search-div {
  width: 70%;
}

.custom-radio {
  display: none;
}

.category-list-label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
}

.category-list-label .category-list {
  position: relative;
  padding-left: 30px;
}

.category-list-label .category-list::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  height: 17px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background: white;
}

.custom-radio:checked + .category-list::before {
  background: #1cd15a;
  border-color: #1cd15a;
}

.forum-image-div {
  padding: 18px 0px;
}
