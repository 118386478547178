/* Breadcrumb.css */
.common-breadcrumb {
  display: flex;
}

.common-breadcrumb ul {
  list-style: none;
  padding: 0;
}

.common-breadcrumb li {
  display: inline;
  list-style: none;
  color: #43567d;
  font-family: "Geologica", sans-serif !important;
}

.common-breadcrumb li a {
  color: #43567d;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 8px;
  line-height: 43px;
}

.common-breadcrumb li a:hover {
  color: #9f1d21;
}

.common-breadcrumb li + li:before {
  content: url("data:image/svg+xml,%3Csvg%20width%3D%227%22%20height%3D%2212%22%20viewBox%3D%220%200%207%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M5.54997%204.41L2.10747%200.967499C1.96695%200.827811%201.77686%200.749405%201.57872%200.749405C1.38058%200.749405%201.1905%200.827811%201.04997%200.967499C0.979677%201.03722%200.923882%201.12017%200.885805%201.21157C0.847729%201.30296%200.828125%201.40099%200.828125%201.5C0.828125%201.59901%200.847729%201.69704%200.885805%201.78843C0.923882%201.87983%200.979677%201.96278%201.04997%202.0325L4.49997%205.4675C4.57027%205.53722%204.62607%205.62017%204.66414%205.71157C4.70222%205.80296%204.72182%205.90099%204.72182%206C4.72182%206.09901%204.70222%206.19704%204.66414%206.28843C4.62607%206.37983%204.57027%206.46278%204.49997%206.5325L1.04997%209.9675C0.908746%2010.1077%200.82901%2010.2983%200.828307%2010.4973C0.827603%2010.6964%200.905991%2010.8875%201.04622%2011.0288C1.18646%2011.17%201.37705%2011.2497%201.57607%2011.2504C1.7751%2011.2511%201.96625%2011.1727%202.10747%2011.0325L5.54997%207.59C5.97133%207.16812%206.20799%206.59625%206.20799%206C6.20799%205.40375%205.97133%204.83188%205.54997%204.41Z%22%20fill%3D%22%2343567D%22/%3E%3C/svg%3E");
  padding: 0px 8px;
}

.bread-crumb-active {
  color: #9f1d21;
}

/* Live Score */

.spring-wrapper {
  border-top: 0.3px solid #fad3d4;
  border-bottom: 0.3px solid #fad3d4;
}

/* Wrapper to contain the scrolling */
.live-score-wrapper {
  overflow: hidden; /* Hide overflow to prevent scrollbars */
  width: 100%; /* Full width of the parent */
  position: relative; /* Position relative for inner positioning */
  height: 54px; /* Set height according to your design */
  background-color: #fff6f6;
  border-top: 0.3px solid #fad3d4;
  border-bottom: 0.3px solid #fad3d4;
  margin-bottom: 5px;
  cursor: pointer;
}

/* Container for scrolling content */
.live-score-container {
  display: flex; /* Flexbox to align items horizontally */
  white-space: nowrap; /* Prevent line breaks */
  position: absolute; /* Position absolute for animation */
  width: 200%; /* Double the width of the content for seamless scrolling */
  animation: scroll-left 30s linear infinite; /* Apply animation */
}

/* Content to be scrolled */
.live-score-content {
  display: flex; /* Align items in a row */
  flex: 1 0 auto; /* Ensure flex item sizing */
}

/* Each item in the scrolling container */
.live-score-item {
  display: inline-flex; /* Inline flex to align items horizontally */
  align-items: center; /* Center items vertically */
  font-family: Geologica;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  text-align: left;
  color: #43567d;
  padding: 10px;
}

/* Keyframes for scroll animation */
@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start at the beginning */
  }
  100% {
    transform: translateX(-50%); /* Move to the end of the duplicated content */
  }
}

/* Progress bar */
#common-progress-your-progress {
  width: 230px;
  height: 100px;
  position: relative;
}

#common-progress-india-progress {
  width: 180px;
  height: 50px;
  position: relative;
}

#common-progress-your-progress:before {
  content: "";
  background: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -26px;
  height: 10px;
  z-index: 3;
}

#common-progress-india-progress:before {
  content: "";
  background: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -26px;
  height: 10px;
  z-index: 3;
}

.gbc-pledge-covered-card {
  display: flex;
  flex-direction: row;
}
.gbc-items {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.gbc-pledge-div {
  position: relative;
  border-right: 1px solid #dedeeb;
}

#common-progress-gbc-india-progress {
  width: 215px;
  height: 110px;
  position: relative;
}

.gbc-india-score-input {
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#common-progress-gbc-india-progress:before {
  content: "";
  background: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -26px;
  height: 10px;
  z-index: 3;
}

#common-progress-gbc-india-progress svg {
  position: absolute;
  /* height: 180px;
  width: 50px; */
  fill: none;
  stroke: #3dcfcf;
  stroke-width: 10;
  stroke-linecap: round;
  /* bottom: 55px; */
  left: 0px;
}

#common-progress-your-progress svg {
  /* height: 270px;
  width: 160px; */
  fill: none;
  stroke: #883dcf;
  stroke-width: 10;
  stroke-linecap: round;
}

#common-progress-india-progress svg {
  position: absolute;
  /* height: 180px;
  width: 50px; */
  fill: none;
  stroke: #3dcfcf;
  stroke-width: 10;
  stroke-linecap: round;
  bottom: 55px;
  left: 0px;
}

.progressbar-text {
  display: none !important;
}

.top-temples-card {
  font-family: "Geologica", sans-serif;
  background-color: #ffffff;
  padding: 20px 25px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}

.head-sec {
  display: flex;
  border-bottom: 1px solid #e5e5ef;
  padding-bottom: 10px;
}

.head-sec-pledge-table {
  display: flex;
  border-bottom: 1px solid #e5e5ef;
  padding-bottom: 14px;
}

.temple-list-head-sec {
  flex: 1;
}

.pledge-head10 {
  font-size: 14px;
  color: #9291a5;
}

.pledge-head-temple {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1e1b39;
}

.pledge-head-temple-points {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1e1b39;
}

.stacked-head-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  color: #1e1b39;
}

.button-text-download {
  font-family: Geologica;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #43567d;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 5px;
}

.temple-progress-div {
  display: flex;
  align-items: center;
}

.user-temple-progress {
  height: 62px;
}

.gbc-temple-progress {
  height: 45px;
}

.temple-name {
  font-family: Geologica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #615e83;
}

.temple-progress-bar-container {
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 10px;
  width: 100%;
}

.progress-bar {
  height: 10px;
  border-radius: 5px;
}

.text-container2 {
  width: 20%;
  text-align: right;
}

.temple-points-count {
  font-family: Geologica;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  color: #615e83;
}

.pledge-cover-card {
  background-color: #ffffff;
  padding: 15.5px 15.5px 8px 15.5px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}

.pledge-image-p-1 {
  padding: 0px 5px;
}

.graph-container-card {
  background-color: #ffffff;
  padding: 30px 16px 0px 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}
.previous-year-span {
  height: 14px;
  width: 14px;
  background-color: #c588ac;
  border-radius: 50%;
}

.current-year-span {
  height: 14px;
  width: 14px;
  background-color: #a493f2;
  border-radius: 50%;
}

.year-rank-card {
  background-color: #ffffff;
  padding: 30px 16px 25px 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}
.comparison-header {
  display: flex;
  padding-bottom: 10px;
}

.comparison-text {
  font-size: 20px;
  color: #43567d;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 20px 0px;
}

.top-name-div {
  width: 25%;
}

.top-progress-div {
  width: 55%;
}

.top-count-div {
  width: 20%;
  text-align: end;
}

.active-path {
  color: #43567d;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 8px;
  line-height: 43px;
}

/* History table */

.view-history-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #43567d;
  margin-top: 25px;
}

.history-table .table-responsive {
  border: 0.5px solid #d5d5d5 !important;
  border-radius: 10px !important;
}

.history-table .history-custom-table {
  margin: 0 !important;
}

.history-table .history-custom-table thead tr th {
  padding: 13px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  background-color: #f4f8ff;
  color: #707c97;
}

.history-table .history-cell-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.1px;
  text-align: left;
  color: #202224;
}

.history-custom-table tbody tr:last-child td {
  border-bottom: none;
}

.history-custom-table tbody tr:last-child th {
  border-bottom: none;
}
.transaction-bottom select {
  width: 244.64px;
  height: 45px;
  top: 157px;
  left: 909px;
  gap: 0px;
  border-radius: 6px;
  border: 1px solid #dbdff2;
  /* opacity: 0px; */
  background-color: #f8f9ff;
}

.transaction-bottom .xl-download {
  width: 246px;
  height: 45px;
  top: 157px;
  left: 1178px;
  border-radius: 6px;
  border: 1px solid #dbdff2;
  padding: 0px 20px;
  background-color: #f8f9ff;
  color: #6e717f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-bottom .history-back {
  width: 44px;
  height: 45px;
  top: 157px;
  left: 92px;
  border: 1px solid #dbdff2;
  background-color: #f8f9ff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.view-history-header {
  display: flex;
}

.year-download {
  display: flex;
  margin-left: auto;
}

.state-table {
  height: 900px;
}

@media screen and (max-width: 1020px) {
  .view-history-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #43567d;
    margin-top: 25px;
  }

  .view-history-header {
    display: flex;
    flex-direction: column;
  }

  .transaction-bottom .history-back {
    display: none;
  }

  .year-download {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .transaction-bottom select {
    width: 100%;
  }
  .transaction-bottom .xl-download {
    width: 100%;
  }

  .state-table {
    margin-top: 10px;
    height: auto;
  }

  .user-temple-progress {
    height: auto;
    display: flex;
    flex-direction: row !important;
  }

  .gbc-temple-progress {
    height: auto;
    flex-direction: row !important;
  }

  .top-temples-card {
    margin-top: 10px;
  }

  .gbc-pledge-covered-card {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .gbc-pledge-div {
    border-right: none;
  }

  .india-points-div {
    width: 40px !important;
    height: 40px !important;
    border-radius: 15px !important;
  }

  .your-points-div {
    width: 40px !important;
    height: 40px !important;
    border-radius: 15px !important;
  }

  .gbc-india-pledge-mobile {
    margin-right: 25px;
  }
}

.your-points-div {
  width: 69px;
  height: 61px;
  border-radius: 20px;
  background-color: #e1c8f8;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.india-points-div {
  width: 70px;
  height: 60px;
  border-radius: 20px;
  background-color: #bff3f5ba;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gbc-india-points-div {
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: #c9ecdeba;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-image {
  margin-right: 10px;
}
