body {
  font-family: "Geologica", sans-serif !important;
  background-color: #f9f9fd !important;
}

p {
  margin-bottom: 0 !important;
}

.login-background-image {
  background-image: url("../../public/images/common/login-theme.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.log-in-container {
  padding: 40px 100px;
  height: 100%;
}

.login-white-background {
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  height: 100%;
}

.login-white-background-registration {
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  height: 110%;
}

.login-image-right {
  background-image: url("../../public/images/common/login-2.png");
  height: auto;
  background-size: cover;
  background-position: fixed;
  border-radius: 20px;
  background-position: 70% center;
}

.login-div center svg {
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
}

.log-in-otp {
  width: 350px;
}

.log-in-number {
  width: 350px;
}

.log-in-container input.form-control {
  background-color: #f5f7fa;
  border: none;
}

.children-components {
  margin-top: 100px;
  background-color: #f9f9fd !important;
}

.quote {
  background-image: url("../../public/images/common/Rectangle 18.png");
  font-family: "Merriweather";
  background-size: cover;
  background-position: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
  display: flex;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: 15px;
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-transform: capitalize;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  margin-top: 23px;
}

.btn-primary {
  color: #784eae;
  width: 100%;
}

.text-center h2 {
  margin-top: 45px;
  text-align: center;
  font-family: "Geologica";
  color: #b4585d;
  font-weight: 900;
  font-size: 22px;
}

.text-center .small a {
  font-family: "Geologica";
  color: #b4585d;
  font-weight: 500;
}

.text-center .small {
  font-size: 11px;
  margin-top: 1px;
  text-align: center;
  font-family: "Geologica";
  color: #2f2e2e;
  font-weight: 600;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: #9f1d21 !important;
}

.card {
  /* background-color: #edf4fb; */
  padding: 57px;
}

.card,
img {
  width: 100;
}

.header-name {
  color: #43567d;
  font-size: 16px;
  font-weight: 600;
}

.header-name-span {
  color: #909aad;
  font-size: 12px;
  font-weight: 600;
}

.navbar-nav .nav-item .nav-menu-list {
  color: #43567d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Geologica", sans-serif;
}

.sub-menu-list {
  color: #43567d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Geologica", sans-serif;
}
.navbar-nav .nav-item a:hover {
  color: #9f1d21;
}

.mobile-notification {
  display: none;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: space-between;
}

.recording-of-scores .notification-icon {
  padding-top: 18px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding-right: 10px;
}

.recording-of-scores .notification-icon .notification-count {
  position: absolute;
  top: 14px;
  right: 8px;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 50%;
}

.recording-of-scores .notification-icon .notification-count-mobile {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 1px 7px;
  border-radius: 50%;
}

.recording-of-scores .burger-background {
  background-color: #f3f3f3;
  padding: 10px 10px;
  border-radius: 50%;
  border: none;
}

.recording-of-scores .navbar-toggler:focus {
  box-shadow: none;
}

.recording-of-scores .notification-icon .notification-image {
  background-color: #f3f3f3;
  border-radius: 50%;
  padding: 10px;
}

.recording-of-scores .profile-icon {
  padding: 10px;
  cursor: pointer;
}

.user-details {
  margin-left: 10px;
}

.recording-of-scores ul li {
  padding: 0px 10px;
}

.welcome-left-div {
  display: flex;
  flex-direction: column;
  /* gap: 60px; */
}

.dashboard-container .user-welcome-card {
  background-color: #ffffff;
  padding: 15.5px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}

.dashboard-container .welcome-text {
  font-size: 32px;
  color: #43567d;
  font-weight: 500;
  /* line-height: 20px; */
}

.dashboard-container .welcome-text-name {
  font-size: 18px;
  color: #43567d;
  font-weight: 600;
  line-height: 43px;
  padding-bottom: 5px;
}

.dashboard-container .welcome-info-text {
  font-size: 13px;
  color: #777777;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 10px;
}

.wish-card {
  margin-top: 30px;
}

.dashboard-container .welcome-text-rank {
  font-size: 17px;
  color: #43567d;
  font-weight: 600;
  line-height: 28px;
}

.dashboard-container .welcome-rank-info {
  font-size: 12px;
  color: #777777;
  font-weight: 400;
  line-height: 28px;
  font-family: "Geologica", sans-serif;
}

.user-rank-count-value {
  font-size: 32px;
  font-weight: 600;
  line-height: 37.6px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #1d1f2c;
  font-family: "Geologica", sans-serif;
  margin-top: 5px;
}

.dashboard-container .welcome-user-details {
  color: #4c5463;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.dashboard-container .user-rank-details {
  color: #777980;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.dashboard-container .welcome-user-count {
  color: #202224;
  font-size: 20px;
  font-weight: 600;
}

.dashboard-container .user-pledge-covered {
  color: #1d1f2c;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}

.dashboard-container .welcome-right .card-border-right {
  border-right: 1px solid #979797;
}

.dashboard-container .user-ranking-card {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}

.ranking-table {
  display: table !important;
}

.dashboard-container .tabs-wrapper {
  display: flex;
  border-radius: 10px !important;
  background-color: #fff;
  /* border: 1px solid #d5d5d5; */
  justify-content: space-evenly;
  align-items: center;
  height: 99px;
  box-shadow: 0px 2px 6px 0px #0d0a2c14;
}

.dashboard-container .tabs-wrapper .event-div {
  width: 24%;
  height: 78%;
}

.dashboard-container .tabs-wrapper .select-tab-div {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f5f9ff;
}

.dashboard-container .tabs-wrapper .active-tab {
  border: 2px solid #fad3d4;
  background-color: #fff0f1 !important;
}

.dashboard-container .tabs-wrapper .tab-event-text {
  font-family: Geologica;
  font-size: 17px;
  font-weight: 500;
  /* line-height: 57px; */
  text-align: center;
  color: #43567d;
}

.dashboard-container .tabs-wrapper .tab-event-text-active {
  font-family: Geologica;
  font-size: 17px;
  font-weight: 500;
  /* line-height: 57px; */
  text-align: center;
  color: #9f1d21;
}

.recharts-legend-wrapper {
  visibility: hidden !important;
}

.dashboard-container .progress-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .modal-dialog .modal-content {
  width: 61% !important;
  margin: auto;
  border-radius: 10px !important;
  height: auto px !important;
}

.edit-profile-wrapper .login-mobile-input {
  display: flex;
  align-items: center; /* Vertically center items */
  position: relative;
}

.edit-profile-wrapper .mobile-icon {
  position: absolute;
  left: 6px;
  top: 6px;
  /* transform: translateY(-50%); */
}

.edit-profile-wrapper .login-mobile-input input.form-control {
  padding-left: 50px;
}

.login-welcome-text {
  color: #9f1d21 !important;
  font-size: 45px;
  font-weight: 600 !important;
  line-height: 47.5px;
  margin-bottom: 20px;
}

.login-welcome-sub-text {
  color: #606060 !important;
  font-size: 18px;
  font-weight: 500 !important;
  padding-bottom: 10px;
  line-height: 26px;
}

.register-mobile-text {
  color: #606060 !important;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 24px;
}

.right-section {
  min-height: 317px;
}

#progress-stats-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
}

.progress-stats-div {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px; */
}

.your-score-input {
  position: absolute;
  left: 18px;
  bottom: 140px;
}

.india-score-input {
  position: absolute;
  right: 18px;
  bottom: 140px;
}

.dashboard-container .progress-line {
  position: relative;
  /* margin: 4px; */
  float: left;
  text-align: center;
  width: 100%;
  height: 100%;
}

.dashboard-container .barOverflow {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dashboard-container .bar-user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  border: 12px solid #eee;
  border-bottom-color: #883dcf;
  border-right-color: #883dcf;
}

.dashboard-container .bar-india {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  box-sizing: border-box;
  border: 12px solid #eee;
  border-bottom-color: #3dcfcf;
  border-right-color: #3dcfcf;
}
.progress-percentage-text {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
}

.hide-semi-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.progress-count {
  font-size: 16px;
  color: #1d1f2c;
  font-weight: 600;
  line-height: 19px;
}

.progress-count-text-your {
  font-size: 11px;
  color: #883dcf;
  font-weight: 600;
  line-height: 19px;
  background-color: #f0ffff;
  padding: 2px 8px 2px 8px;
  border-radius: 6px;
}

.progress-count-text-india {
  font-size: 11px;
  color: #3dcfcf;
  font-weight: 600;
  line-height: 19px;
  background-color: #f0ffff;
  padding: 2px 8px 2px 8px;
  border-radius: 6px;
}

.your-input {
  accent-color: #883dcf;
}

.india-input {
  accent-color: #c9ecdeba;
}

.pledge-responsive .dropdown-toggle {
  padding: 8px 34px;
  background-color: #f8f9ff !important;
  color: #43567d !important;
  border: 1px solid #dbdff2;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
}

.pledge-responsive .dropdown-toggle:hover {
  background-color: #9f1d21 !important;
  border: none;
  color: #fff !important;
}

.update-responsive .dropdown-toggle {
  padding: 10px 10px;
  background-color: #f8f9ff !important;
  color: #43567d !important;
  border: 1px solid #dbdff2;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
}

.update-responsive .dropdown-toggle:hover {
  background-color: #9f1d21 !important;
  border: none;
  color: #fff !important;
}

.transaction-bottom {
  margin-bottom: 70px;
}

.Toastify__progress-bar {
  transform-origin: right !important;
}

.pagination-section {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.pagination-section .all-pagination .page-numbers {
  display: flex;
  gap: 10px;
}

.page-show-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #667085 !important;
}

.pagination-section .all-pagination .page-numbers .page-active {
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  background-color: #9f1d21 !important;
  color: #fff !important;
}

.pagination-section .all-pagination .page-numbers .page-item .page-link {
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #9f1d21;
  background: #f4ecfb;
}

.pagination-section .all-pagination .page-numbers .page-item button:disabled {
  background-color: #e8d0d0;
  color: #1d1f2c;
  cursor: not-allowed;
}

textarea {
  box-shadow: none !important;
}

input {
  box-shadow: none !important;
}

.form-select {
  cursor: pointer !important;
  border: 1px solid #e8e8f2 !important;
  box-shadow: none !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.map-container {
  position: relative;
  right: 150px;
  height: 100%;
  width: 120%;
}

.map-heading {
  position: absolute;
  top: 30px;
  left: 200px;
  font-size: 14px;
  color: #43567d;
  text-align: justify;
}

.temple-login .map-container > svg {
  height: 100% !important;
  width: auto !important;
  margin-top: 50px;
}

.gbc-login .map-container > svg {
  height: 90% !important;
  width: auto !important;
  margin-top: 50px;
}

.HoverInfo {
  position: fixed;
  pointer-events: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  display: flex;
  height: auto;
  flex-direction: column;
  width: auto;
}

.map-container .rsm-legend {
  display: none !important;
}

.Note-text {
  position: absolute;
  bottom: 75px;
  left: 175px;
  display: flex;
  font-size: 13px;
  color: #43567d;
}

.transaction-bottom .custom-table {
  border-collapse: collapse;
}

.transaction-bottom .sticky-header {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 1;
}

.graph-container {
  position: relative;
}

.graph-container .download-button {
  position: absolute;
  cursor: pointer;
  right: 8px;
}

.india-book-points-div {
  padding: 8px;
}

@media (max-width: 768px) {
  .navbar-collapse {
    background-color: #fff;
    border-top: 1px solid #dee2e6;
    padding: 0px 10px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    transform: translateX(0%);
    transition: transform 0.9s ease;
    width: 70%;
    padding-left: 20px;
  }

  .navbar-toggler {
    border-color: transparent;
  }

  .mobile-responsive {
    height: 100% !important;
  }

  .header-profile {
    margin: 0px;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background-color: #fff;
  }

  .btn-primary {
    width: 100%;
    margin-top: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin: 0px;
  }

  .user-header-section {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-bottom: 1px solid #e0e0f6;
    padding-bottom: 6px;
  }

  .mobile-menu-open {
    overflow: hidden;
  }

  .user-header-div {
    height: 84px;
  }

  .header-user-logout {
    display: flex;
    justify-content: flex-start;
    border-top: 1px solid #e0e0f6;
    align-items: center;
    width: 100%;
    padding: 10px 12px 10px 12px;
    position: fixed;
    left: 0;
    bottom: 0;
    top: auto;
    right: 0;
  }

  .header-logout-text {
    margin-top: 10px;
    color: #9f1d21;
    font-size: 16px;
    font-weight: 500;
  }

  .mobile-notification {
    display: block;
  }

  .mobile-notification .rounded-circle {
    margin-right: 15px;
  }

  .dashboard-container .welcome-right {
    margin-top: 20px;
  }
  .dashboard-container .welcome-right .card-border-right {
    border: 0;
  }
  .dashboard-container .quote {
    margin-top: 20px;
  }

  .dashboard-container .rank-margin {
    margin-top: 10px;
  }

  .graph-stats-div {
    margin-top: 20px !important;
  }

  .map-container {
    position: relative;
    right: 0px !important;
    height: 100%;
  }

  .map-container > svg {
    position: sticky !important;
  }

  .Note-text {
    bottom: -15px !important;
    font-size: 10px !important;
    left: 0px !important;
  }

  .canvasjs-chart-tooltip {
    z-index: 10 !important;
  }

  .tabs-wrapper .filter-text {
    font-size: 12px !important;
  }

  .log-in-container {
    padding: 20px;
    height: 100%;
  }

  .log-in-container-registration {
    padding: 20px;
    height: 150%;
  }

  .login-white-background {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-image-right {
    display: none;
  }

  .log-in-container .login-card {
    padding: 0px;
  }

  .login-div center svg {
    height: 100px;
    width: 100px;
    margin-bottom: 7px;
  }

  .login-welcome-text {
    font-size: 30px !important;
  }

  .login-form {
    padding: 0px;
  }

  .register-mobile-text {
    font-size: 14px !important;
  }

  .welcome-left-div {
    gap: 0px;
  }

  .map-container {
    position: relative;
    right: 150px;
    height: 100%;
    width: 100% !important;
  }

  .map-heading {
    left: 0px !important;
  }

  .map-container > svg {
    margin-top: 80px !important;
  }

  .ranking-filters {
    height: 100%;
  }
  .dashboard-container .tabs-wrapper {
    height: 75px;
    gap: 5px;
    padding: 0px 5px;
  }

  .dashboard-container .tabs-wrapper .tab-event-text {
    font-family: Geologica;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #43567d;
  }

  .dashboard-container .tabs-wrapper .tab-event-text-active {
    font-family: Geologica;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #9f1d21;
  }

  .dashboard-container .tabs-wrapper .event-div {
    width: 25%;
    height: 80%;
  }

  .dashboard-container .tabs-wrapper .select-tab-div {
    padding: 5px;
  }

  .select-temple-card {
    display: flex;
    flex-direction: row !important;
    height: 100% !important;
    margin-bottom: 10px;
  }

  .select-temple-drop-down {
    border: none !important;
  }

  .card-border-left {
    border-left: none !important;
  }

  .progress-stats-text {
    top: 180px !important;
  }

  .user-ranking-card {
    margin-bottom: 10px !important;
  }

  .dashboard-container .progress-parent-mobile {
    position: relative;
  }

  .dashboard-container .india-mobile-progress {
    position: absolute;
    top: 23px;
    right: 12px;
    /* border-left: 1px solid #979797; */
    padding-left: 14px;
  }

  #progress-stats-download {
    margin-top: 20px;
  }

  .flex-progress-container svg {
    height: 40px;
    width: 40px;
  }

  .dashboard-container .welcome-user-details {
    font-size: 12px !important;
  }

  .dashboard-container .welcome-user-count {
    font-size: 14px !important;
  }

  .rsa-temple-performance-card {
    height: auto !important;
    overflow-y: hidden !important;
  }

  .rsa-temple-performance-max-card {
    height: auto !important;
    overflow-y: hidden !important;
  }

  .india-book-points-image {
    margin-bottom: 15px;
  }

  .india-book-points-div {
    padding: 0px 0px 16px 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .pledge-responsive .media-image {
    padding: 0px;
  }

  .login-div center svg {
    height: 130px;
    width: 130px;
    margin-bottom: 7px;
  }

  .login-welcome-text {
    font-size: 30px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .progress-wrapper {
    height: 100%;
    width: 90%;
  }
  .pledge-responsive .media-image {
    padding: 0.39rem;
  }
  .media-total-count-div {
    margin-top: 25px;
  }
  .media-bold-text {
    font-size: 14px !important;
  }
}

@media (min-width: 1366px) and (max-width: 1400px) {
  .progress-wrapper {
    height: 81%;
    width: 100%;
  }
  .pledge-responsive .media-image {
    padding: 6px !important;
  }
  .media-total-count-div {
    margin-top: 14px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1440px) {
  .progress-wrapper {
    height: 100%;
    width: 90%;
  }
}

@media (min-width: 1440px) and (max-width: 1920px) {
  .progress-wrapper {
    height: 184.4%;
    width: 64.2%;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .pledge-responsive .media-image {
    padding: 8px;
  }
  .media-total-count-div {
    margin-top: 7px;
  }
  .media-bold-text {
    font-size: 14px !important;
  }
}

@media (min-width: 1921px) {
  .progress-stats-div {
    padding: 0px;
  }
  .progress-wrapper {
    height: 90.2%;
    width: 50%;
  }

  .dashboard-container .bar-india {
    top: 25px;
    left: 25px;
  }

  .pledge-responsive .media-image {
    padding: 8px;
  }
  .media-total-count-div {
    margin-top: 15px;
  }
}

.cell-50 {
  width: 60%;
}

.cell-20 {
  width: 24%;
}

.cell-10 {
  width: 13%;
  padding: 25px 10px !important;
  text-align: end;
}

.header-menu-drop-down {
  position: relative;
}

.header-drop-down-list {
  position: absolute;
}

.header-drop-down-card {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 10px;
  right: 0px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 110px;
  align-items: flex-start;
  border: 1px solid #f2f2f2;
}

.header-drop-down-card li {
  list-style: none;
  margin-bottom: 0px;
}

.header-drop-down-card a {
  text-align: left;
  color: #43567d;
}

.change-language {
  width: 130px;
}

body > .skiptranslate,
body > .goog-te-banner-frame {
  display: none !important;
}

body {
  top: 0px !important;
}

.goog-te-combo {
  background-color: #5570f1 !important;
  border: 1px solid #ccc;
  padding: 10px 2px;
  box-shadow: none !important;
  border-radius: 10px;
  color: #fff;
  width: 100% !important;
}

.skiptranslate > span {
  display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

/* Figma suggestion css */

/* log in */
.login-logo {
  padding: 40px;
  /* max-width: 160px; */
}

.login-form {
  padding: 0px 60px;
}

.otp-form-padding {
  padding: 0px 160px;
}

.login-button {
  background-color: #f3b01b !important;
  border: none !important;
}

.change-number {
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  color: #9f1d21;
}

.resent-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #bb2126;
}

/* Dashboard */
.card-border-left {
  border-left: 1px solid #979797;
}

.pledge-status-text {
  color: #777980;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.flex-progress-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
}

.rank-image {
  background-color: #ffedef;
  border-radius: 10%;
}

.temple-rounded {
  background-color: #f3f3f3;
  padding: 5px;
  border-radius: 50%;
}

.points-rounded {
  border-radius: 40%;
  background-color: #d4d4df;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.points-rounded-india {
  border-radius: 40%;
  background-color: #c9ecdeba;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-distribution {
  padding: 17.5px !important;
}

.points-image {
  border-radius: 50%;
}

.user-rank-all-rank-image {
  border-radius: 10px;
  background-color: rgb(240 223 223);
  /* padding: 5px; */
}

.rank-image-radius {
  border-radius: 50%;
}

.graphs-heading {
  font-size: 20px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.books-count-padding-top {
  padding-top: 24px !important;
}

.books-status-padding-top {
  padding-top: 14px !important;
}

.last-year-tooltip {
  color: #9f5180 !important;
}

.current-year-tooltip {
  color: #9083cc !important;
}

.books-graph-card {
  width: 100%;
  height: 400px;
}

.graph-responsive-container {
  margin-top: 15px;
}

.stats-graph-card {
  border: none !important;
  padding: 4px !important;
}

.stacked-card {
  width: 100% !important;
  height: auto !important;
  border: none !important;
}

.text-area {
  background-color: #f6f9ff !important;
  color: #a8b0c1 !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  border: 1.5px solid #dde3f0 !important;
}

.text-area::placeholder {
  color: #a8b0c1 !important;
  font-size: 18px;
  font-weight: 400;
}

.text-area {
  resize: none;
}

.pagination-list-style {
  list-style: none;
}

/* header */
.user-padding {
  padding-top: 13px;
}

.gbc-padding {
  padding-top: 25px;
}

.temple-img-desktop {
  margin-top: 9px;
  background-color: #f3f3f3;
  padding: 5px;
}

.temple-image-responsive {
  background-color: #f3f3f3;
  padding: 5px;
}

.user-name-responsive {
  text-align: start;
  margin-left: 10px;
}

.logout-button {
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #d4def1;
  background: #9f1d21;
  text-align: center;
  width: 100%;
}

/* contact-us */

.contact-us-text {
  font-weight: 600;
  text-align: left;
  color: #43567d;
  font-size: 26px;
  line-height: 43px;
}

.contact-description {
  color: #6e717f;
  font-size: 15px;
  font-weight: 500;
  padding-left: 20px;
  margin-top: 10px;
}

/* history-page */

.transaction-cell {
  width: 250px;
}

.tab-events {
  height: 60px !important;
}

.history-table {
  overflow-y: auto;
  max-height: 400px;
}

/* resources page */
.resource-card {
  height: auto !important;
}

/* user drop down */

.user-drop-down {
  position: absolute;
  top: 85px;
  padding: 20px 0px;
}

.user-card-pointer {
  position: absolute;
  top: 15px;
  left: 45%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.user-drop-down-card {
  background-color: #ffff;
  padding: 10px 20px;
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;
  top: 95px;
  border: 1px solid #f2f2f2;
  margin-left: 3px;
}

.user-drop-down-card li {
  list-style: none;
  margin-bottom: 0px;
}

.user-drop-down-card a {
  font-size: 18px;
  line-height: 40px;
  font-weight: 300;
  text-align: left;
  color: #43567d;
}

.user-drop-down-card a:hover {
  color: #9f1d21;
}

.logout-text {
  color: #9f1d21 !important;
}

.ranking-filters {
  height: 100px;
}

.status-div {
  padding: 4px 10px 4px 10px;
  gap: 8px;
  border-radius: 8px;
  background-color: #fff0ea;
  text-align: center;
}

/* .pledge-cover-table .table-head th {
  background-color: #f0f1f3;
} */

.progress-stats-text {
  position: absolute;
  top: 215px;
  padding: 10px;
}

.pledge-cover-table .table thead tr th {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #1d1f2c;
  background: #f9f9fc;
  border-top: 1px solid #f0f1f3;
  border-bottom: 1px solid #f0f1f3;
}

.temple-list-card {
  max-height: 140px;
  overflow-y: auto;
}

.temple-list {
  color: #43567d;
  font-size: 15px;
  font-weight: 500;
}

.rsa-temple-performance-card {
  height: 193px;
  overflow-y: auto;
  overflow-x: hidden;
}

.rsa-temple-performance-max-card {
  height: 711px;
  overflow-y: auto;
  overflow-x: hidden;
}

#rsa-temple-stats .table-responsive {
  height: 348px;
  overflow-y: auto;
  overflow-x: hidden;
}

.select-temple-drop-down {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d5d5d5;
}

.select-temple-card {
  border: 1px solid #d5d5d5;
  height: 75px;
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .dashboard-container .user-welcome-card {
    padding: 13px;
  }

  .dashboard-container .welcome-text-name {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .wish-card {
    margin-top: 15px;
  }

  .your-score-input {
    left: 1px;
    bottom: 160px;
  }

  .india-score-input {
    right: 1px;
    bottom: 160px;
  }

  .map-container {
    height: 84%;
  }

  .count-input {
    width: 40px !important;
  }

  .counter-button {
    width: 13px !important;
  }
}

.tabs-wrapper {
  position: relative;
}

.tooltip-custom {
  position: absolute;
  background-color: #fff;
  border: 1px solid #fad3d4;
  padding: 8px;
  text-align: center;
  width: 300px;
  color: #000;
  border-radius: 10px;
  font-size: 14px;
}
/* CSS */
.show-on-small-screen {
  display: none;
}

@media (max-width: 768px) {
  .show-on-small-screen {
    display: block;
  }
}

.no-data-card {
  border: none !important;
}

.no-data-text {
  height: 50vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* General modal adjustments for mobile */
@media (max-width: 768px) {
  .ReactModalPortal .Modal.compare-modal {
    overflow: auto !important;
    background-color: #fff !important;
  }
  .ReactModalPortal .Modal.compare-modal .compare-table-body {
    background: none !important;
    padding: 5px !important;
  }
  .Modal.compare-modal .form-select {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .Modal.compare-modal .text-start {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  .Modal.compare-modal .text-right {
    margin-bottom: 10px !important;
  }

  .Modal.compare-modal .next-select {
    padding-left: 0 !important;
  }
  .Modal.compare-modal .filter-container-comparing {
    height: auto;
    margin: 5px;
  }
  .Modal.compare-modal .custom-comparetable-wrapper {
    padding: 20px 0 !important;
  }

  /* .rdrCalendarWrapper {
    position: absolute !important;
    right: 0px !important;
  } */
}

/* .login-multiple-acount {
margin-top: -40px;
} */
.login-multiple-acount-field {
  margin-top: -20px;
}
.login-multiple-acount-field select {
  margin-bottom: 10px;
}
.unsubscribe-container {
  margin: 2rem auto;
  max-width: 500px;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
}

.unsubscribe-div {
  padding: 2rem;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.unsubscribe-welcome-text {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #333;
}

.unsubscribe-sub-text {
  font-size: 1rem;
  color: #666;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  color: #fff;
}

/* .form-control .date-range-input .full-width {
  position: relative;
}

.rdrCalendarWrapper {
  position: absolute;
  right: 175px;
} */
