/* Styles for mobile view */
@media only screen and (max-width: 768px) {
    .welcome-user-details {
      font-size: 6vw; /* Similar to width * 0.06 */
      font-family: 'Geologica-Medium';
      color: #43567d;
    }
  
    .date-time {
      font-family: 'Geologica-Regular';
      font-weight: 300;
      font-size: 3vw; /* Similar to width * 0.03 */
      color: #7B8291;
    }
    .event-filter{
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 20px;
        padding: 20px 20px !important;
      }
      .event-filter .filter-border{
        border:none;
        height: 0;
      }
      .event-filter .form-select{
        width: 100% !important;
        margin: 10px 20px;
        border: 1px solid #e8e8f2 !important
      }
      .event-table  .card {
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      
      .event-table  .card-title {
        font-weight: bold;
        margin-bottom: 10px;
      }
      
      .event-table  .card-text {
        margin-bottom: 5px;
      }
      
    
      .event-table  .custom-table {
          display: none;
        }
      
      
  }
  
  