.video-wrapper {
  /* margin-top: 120px; */
}

.video-wrapper .row {
  padding: 0px !important;
}

.video-wrapper .video-heading {
  font-weight: 600;
  text-align: left;
  color: #43567d;
  font-size: 26px;
  line-height: 43px;
  margin: 20px 0px;
}

.video-wrapper .form-select {
  height: 57px;
}

.video-wrapper .input-group-text {
  height: 57px;
  border-radius: 10px 0px 0px 10px;
  background-color: #ffffff;
}

.video-wrapper .search-container {
  height: 57px;
}

.video-wrapper .video-card {
  width: auto;
  height: 100%;
  border: 1px solid #e8e8f2;
  border-radius: 15px;
  background-color: #f5f5f5;
}

.video-wrapper .video-card .image {
  padding: 10px;
  width: 100%;
  height: 254px;
  border-radius: 20px;
}

.video-wrapper .video-description {
  padding: 10px;
}

.video-wrapper .video-title {
  font-weight: 500;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  color: #5b6270;
  padding: 10px;
  text-align: center;
}

.video-wrapper .bottom-nav {
  margin-bottom: 70px;
}

.video-holder {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.video-wrapper .video-holder .yt-logo {
  align-items: center;
  color: red;
  display: flex;
  /* height: 100%; */
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 90px;
  width: 100%;
  z-index: 50;
}

.video-wrapper .video-card > a {
  height: 100%;
}

@media screen and (max-width: 1020px) {
  .video-wrapper .video-card {
    margin-top: 10px !important;
    width: 100%;
    height: 100%;
  }

  .video-wrapper .video-card .image {
    margin-top: 10px !important;
    width: 100%;
  }

  .video-wrapper .row .col-sm {
    margin-bottom: 10px;
  }
}
