.quote-image {
  position: relative;
  padding: 30px;
  /* padding-top: 50px; */
}
.quote-image img {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  aspect-ratio: 1;
}

.quote-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quotes-icon {
  position: absolute;
  top: 30px;
  right: 38px;
  padding: 16px;
  background-color: #9f1d21;
  border-radius: 50%;
}

.quote-content {
  display: flex;
  text-align: justify;
  font-size: 16px;
  font-weight: 700;
  color: #3a3a3a;
  line-height: 48px;
  padding: 50px;
}

.quote-item {
  padding: 20px;
}

.content-section {
  display: flex !important;
  border-radius: 10px !important;
}

.content-reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1020px) {
  .quote-content {
    display: flex;
    text-align: justify;
    font-size: 16px;
    font-weight: 700;
    color: #3a3a3a;
    line-height: 30px;
    padding: 10px;
  }
}
