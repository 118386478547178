.blog-wrapper {
  /* margin-top: 120px; */
}

.blog-wrapper .row {
  padding: 0px !important;
}

.blog-top-header {
  display: flex;
  height: 57px;
  flex-direction: row;
  margin-bottom: 25px;
}

.blog-filters-row {
  display: flex;
  height: 57px;
  flex-direction: row;
  gap: 20px;
}

.blog-heading {
  font-weight: 600;
  text-align: left;
  color: #43567d;
  font-size: 26px;
  line-height: 43px;
  margin: 20px 0px;
}

.blog-wrapper .form-select {
  height: 57px;
}

.blog-wrapper .input-group-text {
  height: 57px;
  border-radius: 10px 0px 0px 10px;
  background-color: #ffffff;
}

.blog-wrapper .search-container {
  height: 57px;
}

.blog-wrapper .forum-input-group-text {
  height: 57px;
  border-radius: 10px 0px 0px 10px;
  background-color: #ffffff;
}

.blog-wrapper .forum-input-group-text-reset {
  height: 57px;
  border-radius: 0px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 0px 10px 10px 0px;
}

.blogs-container {
  margin-top: 10px;
}

.blog-card {
  width: 100%;
  height: 456px;
  border: 1px solid #e8e8f2;
  border-radius: 15px;
  background-color: #ffffff;
  padding: 10px;
}

.blog-card img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.card-blog-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-description {
  padding: 10px;
}

.description-card {
  height: 150px;
  padding: 20px 5px;
}

.blog-date {
  height: 15px;
  width: auto;
  background-color: #f0f0ff;
  border-radius: 50px;
  padding: 8px 20px;
  color: #6a6abd;
  font-size: 12px;
  font-weight: 400;
}

.blog-title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #43567d;
  line-height: 23px;
}

.blog-content {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #909aad;
}

.blog-button {
  padding: 11px 34px;
  background-color: #9f1d21;
  font-size: 16px;
  font-weight: 400;
  color: #ffff;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
}

.blog-wrapper .bottom-nav {
  margin-bottom: 70px;
}

.blog-wrapper .blog-filters .form-select .filter-text {
  color: #43567d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.blog-wrapper .blog-filters .form-select {
  color: #43567d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/* .blog-input-left{
  padding-left: 40px;
} */

@media screen and (max-width: 1020px) {
  .blog-card {
    margin-top: 10px !important;
    width: 100%;
    height: 100%;
  }

  .blog-card img {
    margin-top: 10px !important;
    width: 100%;
  }

  .blog-wrapper .blog-top-header {
    padding: 0px !important;
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    gap: 10px;
  }

  .blog-filters-row {
    display: flex;
    height: 100%;
    flex-direction: row;
    gap: 20px;
  }

  .blog-wrapper .blog-top-header .blog-filters-row .input-group {
    flex-wrap: nowrap !important;
  }

  .blog-wrapper .row .col-sm {
    margin-bottom: 10px;
  }

  .blog-top-header {
    display: block;
    height: 57px;
    flex-direction: row;
  }

  .blogs-container-top {
    margin-top: 40px !important;
  }

  .blog-wrapper .questions-count {
    margin-top: 40px !important;
  }

  .blog-card-category{
    display: inline-block;
  }
}

.span-card {
  margin-bottom: 15px;
}
.blog-card-category {
  padding: 5px 10px;
  border-radius: 10px;
  background: #fbe4e4;
  color: #9f1d21;
}

.search-width {
  width: 85px;
}

.search-span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-wrapper .form-select {
  border-radius: 0px 10px 10px 0px !important;
}
