.blog-section {
  /* margin-top: 110px; */
}

.blog-category {
  font-size: 15px;
  font-weight: 400;
  color: #9f1d21;
  background-color: #fbe4e4;
  padding: 8px 20px;
  border-radius: 20px;
}

.main-title {
  color: #43567d;
  font-size: 33px;
  font-weight: 500;
  line-height: 42px;
}

.blog-container {
  margin-bottom: 25px;
}

.blog-container .row {
  gap: 25px;
}

.blog-details-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.description {
  font-weight: 400;
  color: #909aad;
  font-size: 16px;
  text-align: justify;
  line-height: 28px;
  padding: 0px 14px;
}

.description li {
  font-weight: 500;
  text-align: left;
  color: #909aad !important;
  font-size: 18px !important;
  text-align: justify;
}

.blog-details-date {
  color: #909aad;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
}

.blog-container .blog-img {
  border-radius: 10px;
  width: 100%;
  height: 485px;
}

.list-wraper {
  background-color: #ffffff;
}

.blog-right {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  width: 400px;
  /* height: 100%; */
}

.blog-category-list {
  color: #43567d;
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
}

.item-list {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d4def1;
  padding: 10px 0px;
}

.item-text {
  font-size: 20px;
  font-weight: 500;
  color: #939fb7;
  line-height: 34px;
}

.blog-category-count {
  background-color: #9f1d21;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  text-align: center;
  color: #ffffff;
}

.popular-img {
  height: 80px;
  width: 80px;
  max-width: 105px !important;
  border-radius: 8px;
  box-shadow: 0px 0px 9px 4px #43567d1a;
}

.popular-title {
  font-weight: 500;
  text-align: left;
  color: #425b90;
  font-size: 19px;
  line-height: 24px;
}

.popular-info {
  display: flex;
  padding-left: 14px;
  flex-direction: column;
  width: 100%;
  margin-top: 14px;
}

.comments-list {
  display: flex;
  padding-top: 20px;
  margin-bottom: 5px;
}

.comment-border {
  border-top: 1px solid #d4def1;
}

.comments-img {
  border-radius: 50%;
  height: 50px;
  max-width: 50px !important;
  margin-top: 5px;
}

.comment-name {
  color: #43567d;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.comment-date {
  color: #939fb7;
  font-size: 14px;
  font-weight: 500;
}

.comment-content {
  color: #5d6f95;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

.comment-reply {
  color: #43567d;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  cursor: pointer;
}

.blog-post-comment {
  background-color: #9f1d21;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  padding: 8px 20px;
}

.reply-button {
  width: 10%;
}

@media screen and (max-width: 1020px) {
  .blog-container .blog-img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

  /* .blog-section {
    margin-top: 110px;
    padding: 0px 20px;
  } */

  .blog-container .row {
    gap: 0px;
    padding: 0px;
  }

  .blog-right {
    margin-top: 10px;
  }

  .blog-right {
    width: 100%;
  }

  .blog-category {
    display: block;
  }

  .blog-back {
    display: none;
  }
}

.blog-details-image {
  margin-top: 20px;
  padding: 0px 14px;
}

.blog-container .user-comments {
  padding: 10px 14px;
}
