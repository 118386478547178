.profile-wrapper {
  /* margin-top: 110px; */
}

.profile-wrapper .row {
  padding: 0px !important;
}

.profile-image {
  display: flex;
  border-bottom: 1px solid #d4def1;
  padding: 10px;
}

.profile-user-name {
  padding-left: 10px;
  font-size: 17px;
  font-weight: 600;
  color: #43567d;
  line-height: 24px;
  font-family: "Geologica", sans-serif !important;
}

.profile-name-text {
  font-size: 12px;
  font-weight: 600;
  color: #909aad;
  line-height: 24px;
}

.profile-left {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.profile-right {
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  height: 100%;
}

.profile-item-list {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.profile-item-text {
  font-size: 15px;
  font-weight: 400;
  color: #6b717b;
  line-height: 20px;
  font-family: "Geologica", sans-serif !important;
}

.list-border {
  border-radius: 10px;
  border: 1px solid #d4def1;
}

.user-img {
  border-radius: 50%;
  height: 50px;
  max-width: 50px !important;
}

.profile-left ul {
  padding: 0px;
}

.profile-left ul li {
  list-style-type: circle;
  cursor: pointer;
}

.profile-left ul li::marker {
  content: •;
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
}

.profile-left ul li:hover {
  background-color: #ffedef;
}

.profile-left ul li a:hover {
  color: #574c4d;
}

.profile-tab-active {
  background-color: #ffedef;
  color: #574c4d;
}

.profile-left .log-out {
  color: #d55f5a;
}

.profile-left .logout-section {
  border: 1px solid #d55f5a;
  border-radius: 10px;
}

.notification-wrapper {
  padding: 10px;
}

.notification-span-dark {
  padding: 17px;
  background-color: #9f1d21;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: relative;
}

.notification-span-dark svg {
  position: absolute;
  top: 17%;
  right: 17%;
}

.notification-header {
  /* display: flex; */
  justify-content: space-between;
  border-top: 1px solid #d4def1;
  align-items: center;
  padding: 10px;
  padding-bottom: 0px;
}

.notification-header .delete {
  cursor: pointer;
  margin-left: 20px;
}

.notification-text {
  font-size: 16px;
  font-weight: 600;
  color: #43567d;
  padding-left: 10px;
  margin: 0 !important;
}

.notification-subject {
  color: #9f1d21;
  font-size: 15px;
  font-weight: 600;
  padding: 5px 10px;
}

.notification-description {
  color: #555b69;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 44px;
  margin-top: 10px;
}

.notification-details-description {
  color: #555b69;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0px;
  margin-left: 11px;
}

.notification-title {
  color: #9f1d21;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
  margin: 0 !important;
}

.notification-wrapper p {
  padding-bottom: 0px !important;
}

.load-more-div {
  border-top: 1px solid #d4def1;
}

.notification-load-more {
  background-color: #9f1d21;
  border-radius: 5px;
  width: 160px;
  height: 45px;
  color: #ffffff;
  padding: 10px;
  border: none;
  margin-top: 25px;
}

.notification-load-more:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.header-text {
  color: #43567d;
  font-weight: 500;
  font-size: 21px;
  padding: 5px 0px;
}

.edit-profile-wrapper .upload-photo {
  display: flex;
  gap: 20px;
}

.edit-profile-wrapper .upload-photo p {
  margin: 0;
}

.edit-profile-wrapper .upload-photo .drag-drop {
  width: 100%;
  cursor: pointer;
}

.edit-profile-wrapper .upload-photo .drag-drop .upload-div {
  padding: 14px;
  text-align: center;
  height: auto;
  border-radius: 10px;
}

.edit-profile-wrapper .upload-photo .border {
  border: 2px dashed #aaa !important;
  min-height: 105px;
}

.edit-profile-wrapper .upload-label {
  cursor: pointer;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.label-text {
  font-size: 15px;
  font-weight: 500;
  color: #43567d;
}

.edit-profile-wrapper .form-control {
  background-color: #f8f9ff;
  font-weight: 400;
  color: #6e717f;
  font-size: 14px;
}

.edit-profile-wrapper .input-bottom {
  margin-bottom: 10px;
}

.edit-profile-wrapper .form-select {
  background-color: #f8f9ff;
  font-weight: 500;
  color: #6e717f;
}

.file-upload-text {
  color: #353535;
  font-size: 14px;
  font-weight: 500;
}

.notification-title-span {
  color: #909aad;
  font-size: 12px;
  font-weight: 600;
}

@media screen and (max-width: 1020px) {
  .profile-right {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .edit-profile-wrapper .row {
    flex-direction: row;
  }
  .notification-description {
    padding: 0;
  }

  .notification-subject {
    padding: 0px 0px 0px 0px;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px !important;
  }

  .notification-title-span {
    margin-left: 40px;
  }
}

.profile-note {
  background-color: #f8f9ff;
  font-weight: 400;
  color: #6e717f;
  font-size: 14px;
}

.contact-us-image {
  text-align: center;
  padding: 39px 0px;
}
